import * as crypto from 'crypto';
import { RsaPrivateKey } from 'crypto';

export function EncryptData(data: string | null | undefined): string {
  if (!data) {
    return '';
  }
  const publicKey =
    '-----BEGIN PUBLIC KEY-----\n' +
    'MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBALCzLauYZ5EFgW0Sd2eIrSu3SqB7b0z3\n' +
    'Ma4sLWpvehcpCg0PYeEC6ljLJ0UROf9ZTOdBMQKhHtd8qiBMIpXrjY0CAwEAAQ==\n' +
    '-----END PUBLIC KEY-----';

  const buffer = Buffer.from(data); // 将要加密的数据转换为二进制数据
  const encrypted = crypto.publicEncrypt(publicKey, buffer); // 使用公钥加密数据
  return encrypted.toString('base64'); // 将加密后的数据转换为 Base64 字符串
}

export function DecryptData(data: string | null | undefined): string {
  if (!data) {
    return '';
  }

  const privateKey = process.env.RSA_PRIVATE_KEY as RsaPrivateKey;

  const buffer = Buffer.from(data, 'base64'); // 将加密后的数据解码成二进制数据
  const decrypted = crypto.privateDecrypt(privateKey, buffer); // 使用私钥解密数据
  return decrypted.toString('utf8'); // 将解密后的数据转换为字符串
}
