import { FC, useState } from 'react';
import { Avatar, Dropdown, MenuProps, message, Modal, Popover } from 'antd';
import { useClientSession, useFetch } from '@/libs/fe/hooks';
import { CaretDownOutlined, CaretUpOutlined, LogoutOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { signOut } from 'next-auth/react';

import styles from './style.module.scss';
import { useRouter } from 'next/router';
import { GrantedTenantListVO } from '@/pages/api/tenant/types';
import { changeTenant } from '@/components/ChangeTenant';

interface Props {
  mode?: 'short' | 'full';
  size?: number;
  isPureAvatar?: boolean;
  grantTenantList?: GrantedTenantListVO[] | null;
  whiteName?: boolean;
  //点击头像是否使用默认的click事件
  openDefaultClickAvatar?: boolean;
  //点击头像事件，如果是null,则可选择开启默认事件
  onClickAvatar?: () => void;
}

const UserAvatar: FC<Props> = ({
  mode = 'short',
  size = 24,
  isPureAvatar = true,
  grantTenantList = [],
  whiteName = false,
  onClickAvatar,
  openDefaultClickAvatar = false
}) => {
  const { session, status } = useClientSession();
  const router = useRouter();
  const [modal, setModal] = useState({
    isOpen: false,
    tenantUid: '',
    tenantName: ''
  });

  const [accountSettingIsHover, setAccountSettingIsHover] = useState(false);
  const [existIsHover, setExistIsHover] = useState(false);

  const [tenantDropdownIsSpan, setTenantDropdownIsSpan] = useState(false);

  const handleOk = async () => {
    const success = await changeTenant(modal.tenantUid, session, `${location.origin}/workspace#/dashboard/home`);
    console.log('changeTenant ： ', success);
    if (!success) {
      return;
    }
    setModal({
      isOpen: false,
      tenantUid: '',
      tenantName: ''
    });
    window.location.reload();
  };

  const handleCancel = () => {
    setModal({
      isOpen: false,
      tenantUid: '',
      tenantName: ''
    });
  };

  const clickAccountSetting = () => {
    const target = '/workspace#/more/setting_account';
    if (router.asPath === target) {
      return;
    }
    window.location.href = '/workspace#/more/setting_account';
  };

  const clickLogout = async () => {
    try {
      await fetch('/api/user/logOut', { method: 'POST' });
    } catch (err) {
      console.log('记录登出日志失败', err);
    }
    await signOut();
    window.location.href = `/`;
  };

  const items: MenuProps['items'] = [
    status === 'unauthenticated'
      ? {
          key: 'login',
          label: (
            <div
              onClick={() => {
                window.location.href = '/#/login';
                window.location.reload();
              }}
            >
              立即登陆
            </div>
          )
        }
      : null,
    // status === 'authenticated'
    //   ? {
    //       key: 'changepwd',
    //       label: (
    //         <div
    //           onClick={() => {
    //             window.location.href = `/#/updSecret`;
    //             if (router.pathname === '/') {
    //               window.location.reload();
    //             }
    //           }}
    //         >
    //           修改密码
    //         </div>
    //       )
    //     }
    //   : null,
    status === 'authenticated'
      ? {
          key: 'settingAccount',
          label: <div onClick={clickAccountSetting}>账号设置</div>
        }
      : null,
    status === 'authenticated'
      ? {
          key: 'logout',
          label: <div onClick={clickLogout}>退出登录</div>
        }
      : null
  ].filter(Boolean);

  const tenantItems = grantTenantList?.map(tenant => {
    return {
      key: `tenant-${tenant.tenantUid}`,
      label: (
        <div
          onClick={async () => {
            if (session?.user?.loginTenantUid === tenant.tenantUid) {
              message.warning(`当前正处于【${tenant.name}】`);
              return;
            }
            setModal({
              isOpen: true,
              tenantUid: tenant.tenantUid,
              tenantName: tenant.name
            });
          }}
        >
          {tenant.name}
        </div>
      )
    };
  });

  const tenantListSpan = () => {
    const loginTenant = grantTenantList?.find(t => t.tenantUid === session?.user?.loginTenantUid);

    const tenantName = loginTenant ? loginTenant.name : '请选择想登录的团队';

    return (
      <span className={styles['pop-container-head-tenant']}>
        {tenantName}
        {grantTenantList && grantTenantList.length > 1 ? tenantDropdownIsSpan ? <CaretUpOutlined /> : <CaretDownOutlined /> : null}
      </span>
    );
  };

  function clickAvatar() {
    if (onClickAvatar) {
      onClickAvatar();
    }
  }

  const innerOnClickAvatar = () => {};

  const accountSettingHandleMouseEnter = () => {
    setAccountSettingIsHover(true);
  };

  const accountSettingHandleMouseLeave = () => {
    setAccountSettingIsHover(false);
  };

  const existHandleMouseEnter = () => {
    setExistIsHover(true);
  };

  const existHandleMouseLeave = () => {
    setExistIsHover(false);
  };

  const popOverContent =
    !onClickAvatar && openDefaultClickAvatar ? (
      <div className={styles['pop-container']}>
        <div className={styles['pop-container-head']}>
          <UserAvatar size={48} onClickAvatar={innerOnClickAvatar} />
          <div style={{ marginLeft: 8, display: 'flex', flexDirection: 'column' }}>
            <span className={styles['pop-container-head-userName']}>{session?.user?.nickName}</span>
            {grantTenantList?.length === 1 ? (
              tenantListSpan()
            ) : (
              <Dropdown
                menu={{ items: tenantItems }}
                placement="bottom"
                trigger={['click']}
                // arrow={true}
                onVisibleChange={setTenantDropdownIsSpan}
              >
                {tenantListSpan()}
              </Dropdown>
            )}
          </div>
        </div>
        <div className={styles['pop-container-foot']}>
          <div
            className={`${styles['pop-container-foot-account-setting']} ${accountSettingIsHover ? styles['pop-container-foot-cursor-backgroup'] : ''}`}
            onMouseEnter={accountSettingHandleMouseEnter}
            onMouseLeave={accountSettingHandleMouseLeave}
            onClick={clickAccountSetting}
          >
            <SettingOutlined style={{ width: 18, height: 18 }} />
            <label className={styles['pop-container-foot-account-setting-label']}>账号设置</label>
          </div>
          <div
            className={`${styles['pop-container-foot-logout']} ${existIsHover ? styles['pop-container-foot-cursor-backgroup'] : ''}`}
            onMouseEnter={existHandleMouseEnter}
            onMouseLeave={existHandleMouseLeave}
            onClick={clickLogout}
          >
            <LogoutOutlined style={{ width: 18, height: 18 }} />
            <label className={styles['pop-container-foot-account-logout-label']}>退出登陆</label>
          </div>
        </div>
      </div>
    ) : null;

  return (
    <>
      <Popover placement="bottomRight" content={popOverContent} trigger="click" arrow={false} overlayInnerStyle={{ padding: 0 }}>
        <div className={!isPureAvatar ? styles['user-dropmenu'] : ''} onClick={clickAvatar}>
          <div className={styles['user-avatar-container']}>
            <Avatar
              src={status === 'authenticated' ? 'https://img.alicdn.com/imgextra/i1/O1CN01zlh18U1p04pr854J6_!!6000000005297-2-tps-64-64.png' : null}
              size={size}
              icon={<UserOutlined />}
            />
            {mode === 'full' ? (
              <div style={{ marginLeft: 8 }} className={styles['font']}>
                <span style={whiteName ? { color: 'white' } : {}}>{session?.user?.nickName}</span>
              </div>
            ) : null}
          </div>
        </div>
      </Popover>

      {/*  <Dropdown
        className={!isPureAvatar ? styles['user-dropmenu'] : ''}
        disabled={isPureAvatar}
        menu={{ items }}
        placement="bottom"
        trigger={['click']}
      >
        <div className={styles['user-avatar-container']}>
          <Avatar
            src={
              status === 'authenticated'
                ? 'https://img.alicdn.com/imgextra/i1/O1CN01zlh18U1p04pr854J6_!!6000000005297-2-tps-64-64.png'
                : ''
            }
            size={size}
            icon={<UserOutlined />}
          />
        </div>
      </Dropdown>*/}
      {/*   {mode === 'full' ? (
        <div className={styles['user-name']}>
          <div>
            <span>{session.user.nickName}</span>
          </div>
          {grantTenantList?.length === 1 ? (
            tenantListSpan()
          ) : (
            <Dropdown
              menu={{ items: tenantItems }}
              placement="bottom"
              trigger={['click']}
            >
              {tenantListSpan()}
            </Dropdown>
          )}
        </div>
      ) : (
        ''
      )}*/}

      <Modal title="切换租户" okText="切换" cancelText="取消" open={modal.isOpen} onOk={handleOk} onCancel={handleCancel}>
        <p>
          请确认是否要切换到【{modal.tenantName}
          】团队？当前页面上的内容将被关闭。
        </p>
      </Modal>
    </>
  );
};

export default UserAvatar;
