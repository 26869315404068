export const enum SpellGroupStatusEnum {
  /**
   * 上线
   */
  ACTIVE = 'ACTIVE',
  /**
   * 下线
   */
  INACTIVE = 'INACTIVE'
}

export const SpellGroupStatusToLabelMap: {
  [key in keyof typeof SpellGroupStatusEnum]: string;
} = {
  ACTIVE: '上线',
  INACTIVE: '下线'
};

export const enum SpellStatusEnum {
  /**
   * 上线
   */
  ACTIVE = 'ACTIVE',
  /**
   * 下线
   */
  INACTIVE = 'INACTIVE',
  /**
   * 开发中
   */
  AUDIT = 'AUDIT'
}

export const SpellStatusToLabelMap: {
  [key in keyof typeof SpellStatusEnum]: string;
} = {
  ACTIVE: '上线',
  INACTIVE: '下线',
  AUDIT: '开发中'
};

export const enum ExecutionOptConfigStatusEnum {
  /**
   * 草稿
   */
  INIT = 'INIT',
  /**
   * 生效
   */
  ACTIVE = 'ACTIVE',
  /**作废
   *
   */
  CANCEL = 'CANCEL'
}

export const enum ExecutionOptConfigEffectEnum {
  /**
   * 正面
   */
  POSITIVE = 'POSITIVE',
  /**
   * 负面
   */
  NEGATIVE = 'NEGATIVE',
  /**
   * 中性
   */
  NEUTRAL = 'NEUTRAL'
}

export const enum SpellTypeEnum {
  /**
   * 普通咒语
   */
  NORMAL = 'NORMAL',
  /**
   * 后执行优化咒语
   */
  OPTIMIZE = 'OPTIMIZE',
  /**
   * 测试
   */
  TEST = 'TEST',
  /**
   * 资料库
   */
  SPLIT_ARTICLE = 'SPLIT_ARTICLE', // 拆分文章
  PARAGRAPH_ANALYSIS = 'PARAGRAPH_ANALYSIS', // 段落分析
  PARAGRAPH_SUMMARY = 'PARAGRAPH_SUMMARY', // 段落概括
  LIBRARY_STRUCTURE = 'LIBRARY_STRUCTURE', // 资料库结构化
  /**
   * 小红书
   */
  XIAOHONGSHU_BATCH = 'XIAOHONGSHU_BATCH',
  // XIAOHONGSHU_BATCH_TITLE = 'XIAOHONGSHU_BATCH_TITLE',
  // XIAOHONGSHU_BATCH_HEADING = 'XIAOHONGSHU_BATCH_HEADING',
  // XIAOHONGSHU_BATCH_CONTENT = 'XIAOHONGSHU_BATCH_CONTENT',
  XIAOHONGSHU_BATCH_BACKEND = 'XIAOHONGSHU_BATCH_BACKEND',
  /**
   * 微调咒语
   */
  SFT = 'SFT',
  SFT_ALIYUN_TITLE = 'SFT_ALIYUN_TITLE'
}

export const enum BizTypeEnum {
  SPELL = 'SPELL',
  SPELL_GROUP = 'SPELL_GROUP'
}

export const enum TenantUserStatusEnum {
  ACTIVE = 'ACTIVE',
  AUDITING = 'AUDITING',
  FREEZE = 'FREEZE'
}

export const UserStatusMap: {
  [key: string]: string;
} = {
  ACTIVE: '正常',
  FREEZE: '冻结中',
  AUDITING: '待确认'
};

export const enum TenantTypeEnum {
  NORMAL = 'NORMAL',
  MANAGE = 'MANAGE'
}

export const enum TenantStatusEnum {
  ACTIVE = 'ACTIVE',
  FREEZE = 'FREEZE'
}

export const enum TenantPayTypeEnum {
  POST_PAID = 'POST_PAID'
}

export const enum NoticeTypeEnum {
  SYSTEM = 'SYSTEM',
  NORMAL = 'NORMAL',
  EMPLOYEE_CHANGES = 'EMPLOYEE_CHANGES'
}

export const enum TenantQuotaStatusEnum {
  /**
   * 待生效
   */
  WAIT_ACTIVE = 'WAIT_ACTIVE',
  /**
   * 生效中
   */
  ACTIVE = 'ACTIVE',
  /**
   * 已结束
   */
  FINISH = 'FINISH',
  /**
   * 已欠费
   */
  ARREARAGE = 'ARREARAGE',
  /**
   *已过期
   */
  EXPIRE = 'EXPIRE'
}

export const enum TenantQuotaChangeLogTypeEnum {
  /**
   * 充值
   */
  RECHARGE = 'RECHARGE',
  /**
   * 退款
   */
  REFUND = 'REFUND'
}

export const enum TenantQuotaRecordTypeEnum {
  IN = 'IN',
  OUT = 'OUT'
}

export const enum SmsTypeEnum {
  MODIFY_SECRET = 'MODIFY_SECRET', //修改密码
  REGISTRY = 'REGISTRY', //注册账号
  MODIFY_PHONE = 'MODIFY_PHONE', //修改手机号
  LOGIN = 'LOGIN' //登陆
}

export const enum SmsStatusEnum {
  INIT = 'INIT', //待发送
  SUCCESS = 'SUCCESS', //发送成功
  FAILED = 'FAILED' //发送失败
}

export const enum ProjectEnvEnum {
  dev = 'dev',
  pre = 'pre',
  production = 'production'
}

export const SmsType = {
  MODIFY_SECRET: {
    SMS_SIGN_NAME: process.env.SMS_MODIFY_SECRET_SIGN_NAME,
    SMS_TEMPLATE_CODE: process.env.SMS_MODIFY_SECRET_TEMPLATE_CODE
  },
  REGISTRY: {
    SMS_SIGN_NAME: process.env.SMS_REGISTRY_SIGN_NAME,
    SMS_TEMPLATE_CODE: process.env.SMS_REGISTRY_TEMPLATE_CODE
  },
  MODIFY_PHONE: {
    SMS_SIGN_NAME: process.env.SMS_MODIFY_PHONE_SIGN_NAME,
    SMS_TEMPLATE_CODE: process.env.SMS_MODIFY_PHONE_TEMPLATE_CODE
  },
  LOGIN: {
    SMS_SIGN_NAME: process.env.SMS_LOGIN_SIGN_NAME,
    SMS_TEMPLATE_CODE: process.env.SMS_LOGIN_TEMPLATE_CODE
  }
};

export const enum MenuCodeEnum {
  x2_m_dashboard = 'x2_m_dashboard', //	工作台
  x2_m_dashboard_home = 'x2_m_dashboard_home', //	工作台

  x2_m_spellgroup = 'x2_m_spellgroup', //咒语库
  x2_m_spellgroup_history = 'x2_m_spellgroup_history', //	我的执行历史
  x2_m_library = 'x2_m_library', //	资料库

  x2_m_team = 'x2_m_team', //	团队管理
  x2_m_team_overview = 'x2_m_team_overview', //	团队总览
  x2_m_team_user = 'x2_m_team_user', //	成员管理
  x2_m_team_quota = 'x2_m_team_quota', //	额度消耗明细
  x2_m_project = 'x2_m_project', //项目组成员一级菜单
  x2_m_project_group = 'x2_m_project_group', //	项目组成员

  x2_m_system = 'x2_m_system', //	系统管理
  x2_m_system_model = 'x2_m_system_model', //	模型管理
  x2_m_system_role = 'x2_m_system_role', //	角色定义
  x2_m_system_permission = 'x2_m_system_permission', //	权限定义
  x2_m_system_menu = 'x2_m_system_menu', //	菜单定义
  x2_m_execution_opt_mng = 'x2_m_execution_opt_mng', //	后执行管理
  x2_m_system_tenant = 'x2_m_system_tenant', //	租户管理
  x2_m_system_history = 'x2_m_system_history', // 执行记录
  x2_m_system_spellgroup = 'x2_m_system_spellgroup', // 咒语库管理
  x2_m_system_spellgroup_development = 'x2_m_system_spellgroup_development', // 咒语库管理

  x2_m_article = 'x2_m_article', //	新闻稿

  x2_m_more = 'x2_m_more', //	更多
  x2_m_setting_account = 'x2_m_setting_account', //	账号设置
  x2_m_use_help = 'x2_m_use_help', //	如何使用
  x2_m_data_security_statement = 'x2_m_data_security_statement', //	数据安全声明

  x2_m_poster = 'x2_m_poster',
  x2_m_poster_list = 'x2_m_poster_list',
  x2_m_poster_designer = 'x2_m_poster_designer'
}

export const enum RoleCodeEnum {
  // 普通用户
  normal_user = 'normal_user',

  // 租户管理员
  tenant_admin = 'tenant_admin',

  // 咒语开发
  spell_developer = 'spell_developer',

  // 系统管理员
  system_admin = 'system_admin'
}

export const enum LoginTypeEnum {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT'
}

export const enum PermissionCodeEnum {
  // 咒语使用
  x2_p_spell_use = 'x2_p_spell_use',

  // 团队管理
  x2_p_team_manage = 'x2_p_team_manage',

  // 咒语开发
  x2_p_spell_develop = 'x2_p_spell_develop',

  // 系统管理
  x2_p_system_config = 'x2_p_system_config',

  /**
   * 系统咒语开发
   */
  x2_p_system_spell_develop = 'x2_p_system_spell_develop',
  /**
   * 资料库操作权限
   */
  x2_p_operate_library = 'x2_p_operate_library',
  /**
   * 项目组权限
   */
  x2_p_project_group = 'x2_p_project_group'
}

export const enum AiModelTypeEnum {
  GPT = 'GPT',

  QWEN = 'QWEN',

  BAILIAN = 'BAILIAN',

  OTHER = 'OTHER'
}

export const enum AiModelStatusEnum {
  // 上线
  ACTIVE = 'ACTIVE',
  //异常
  ERROR = 'ERROR',
  // 下线
  INACTIVE = 'INACTIVE'
}

export const enum AiModelAuthStatusEnum {
  // 上线
  ACTIVE = 'ACTIVE',
  ERROR = 'ERROR',
  // 下线
  INACTIVE = 'INACTIVE'
}

export const enum SkuCodeEnum {
  /**
   * 文案生成
   */
  text_generation = 'text_generation'
}

export const enum TenantQuotaRecordRelTypeEnum {
  /**
   * 正常执行
   */
  EXECUTION = 'EXECUTION',
  /**
   * 代替执行
   */
  SUBSTITUTE_EXECUTION = 'SUBSTITUTE_EXECUTION',

  /**
   * 抵消
   */
  OFFSET = 'OFFSET'
}

export const enum LibraryTypeEnum {
  /**
   * 资料库
   */
  LIBRARY = 'LIBRARY',
  /**
   * 资料
   */
  MATERIAL = 'MATERIAL'
}

export const enum LibraryVisibleRange {
  ALL = 'ALL',
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
  PROJECT_GROUP = 'PROJECT_GROUP'
}

export const enum LibraryMaterialActionEnum {
  SAVE = 'SAVE',
  ANALYSIS = 'ANALYSIS'
}

export const enum StructureTypeEnum {
  /**
   * 文章标题
   */
  title = 'title',
  /**
   * 文章主题
   */
  topic = 'topic',
  /**
   * 段落-概述/段落大意
   */
  paragraphs_overview = 'paragraphs_overview',
  /**
   * 段落-原文
   */
  paragraphs_original = 'paragraphs_original'
}

export const enum SpellGroupPermissionStatus {
  forbid = 'forbid',
  usable = 'usable'
}

export const enum ProjectGroupStatusEnum {
  ON = 'ON',
  OFF = 'OFF'
}

export const ProjectGroupStatusList = [
  {
    value: 'ON',
    label: '上线',
    color: 'success'
  },
  {
    value: 'OFF',
    label: '下线',
    color: 'default'
  }
] as any;

//注册账号方式
export type RegistryType = 'REGISTRY' | 'ACCOUNT' | 'PHONE' | 'CONFIRM';
