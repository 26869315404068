import React, { FC, useEffect, useState } from 'react';

import { Button, Form, Input, message, Modal } from 'antd';

import { ArrowRightOutlined } from '@ant-design/icons';
import { signIn, useSession } from 'next-auth/react';
import { EncryptData } from '@/libs/RSAUtil';

import styles from './style.module.scss';
import { useRouter } from 'next/router';
import { useFetch } from '@/libs/fe/hooks';
import { GrantedTenantList, GrantedTenantListVO } from '@/pages/api/tenant/types';
import { parseHashUrl } from '@/libs/util';
import { HomeType } from '@/pages';
import { SessionType } from '@/types';

const ChangeTenant: FC<{
  updHomeType: (v: HomeType) => void;
}> = ({ updHomeType }) => {
  const { data: session } = useSession();
  const router = useRouter();
  const urlResult = parseHashUrl(router.asPath);
  const callbackUrl = (urlResult?.params?.callbackUrl as string) || `${location.origin}/workspace#/dashboard/home`;

  const { data: grantTenantList, loading } = useFetch<GrantedTenantListVO[]>(async () => {
    if (!session?.user?.uid) {
      return [];
    }
    return fetch('/api/tenant/getGrantedTenantList', { method: 'GET' })
      .then(res => res.json())
      .then(({ data }: GrantedTenantList) => {
        return data || [];
      });
  }, [session?.user?.uid]);

  const { loading: changeTenantLoading } = useFetch<void>(async () => {
    if (session && grantTenantList?.length === 1) {
      await changeTenant(grantTenantList[0].tenantUid, session as SessionType, callbackUrl);
    }
  }, [grantTenantList?.length]);

  return (
    <Modal
      title={<h2 style={{ fontWeight: 'bold', marginLeft: 24 }}>请选择您想登录的团队</h2>}
      centered
      open={true}
      footer={null}
      onCancel={() => updHomeType('home')}
      width="500px"
    >
      <div style={{ minHeight: '300px' }}>
        {loading ? (
          <h2>团队信息加载中...</h2>
        ) : grantTenantList?.length ? (
          changeTenantLoading ? (
            <h2>登陆中...</h2>
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              {grantTenantList?.map(item => {
                return (
                  <div
                    key={`t-${item.tenantUid}`}
                    className={styles['tenantItem']}
                    onClick={async () => {
                      await changeTenant(item.tenantUid, session as SessionType, callbackUrl);
                    }}
                  >
                    <div
                      style={{
                        marginLeft: '20px',
                        float: 'left',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <h3 style={{ margin: 0 }}>{item.name}</h3>
                    </div>
                    <div
                      style={{
                        float: 'right',
                        flexDirection: 'column',

                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <ArrowRightOutlined style={{ alignItems: 'center', marginRight: '20px' }} />
                    </div>
                    {item.isLastLogin ? (
                      <div
                        style={{
                          position: 'absolute',
                          top: 0,
                          right: 0,
                          padding: '2px 8px',
                          color: 'blue',
                          backgroundColor: 'rgba(0, 137, 255, 0.12)',
                          borderRadius: '0 8px 0 8px',
                          fontSize: 12
                        }}
                      >
                        上次登录
                      </div>
                    ) : null}
                  </div>
                );
              })}
            </div>
          )
        ) : (
          <h2>您当前未加入任何团队</h2>
        )}
      </div>
    </Modal>
  );
};

export async function changeTenant(tenantUid: string, session: SessionType | null, callbackUrl: string) {
  if (tenantUid === session?.user?.loginTenantUid) {
    message.warning('您当前正处于该团队，无需切换');
    return false;
  }
  return await fetch('/api/user/getChangeTenantToken', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ tenantUid: tenantUid })
  })
    .then(res => res.json())
    .then(async ({ data, success, error }) => {
      if (success) {
        return await signIn('credentials', {
          redirect: false,
          username: EncryptData(session?.user?.userName),
          changeTenantToken: data,
          loginType: 'CHANGE_TENANT'
        }).then(res => {
          if (res?.ok) {
            message.success('登录团队成功');
            location.href = callbackUrl;
            return true;
          } else {
            message.error(res?.error || '登录失败');
            return false;
          }
        });
      } else {
        message.error(error || '进入团队失败');
        return false;
      }
    });
}

export default ChangeTenant;
