import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FetchControllerType, executeAiFetch, parseUrlParams, stopExecAiFetch } from '@/libs';
import { CommonObjectType, AiExecuteRequestType, SessionType } from '@/types';
import { AiModelItemVO } from '@/pages/api/ai-model/types';
import { ExecutionOptConfigItem } from '@/pages/api/execution-opt-config/types';
import { useSession } from 'next-auth/react';
import boolean from 'async-validator/dist-types/validator/boolean';
import { AiTestRequest } from '@/pages/api/ai/test';
import BigNumber from 'bignumber.js';
import { supportArrayAt } from '@/libs/fe/commonUtil';
import { message } from 'antd';
import { HookAPI } from 'antd/lib/modal/useModal';

export type FetchControllerRes = {
  fetchController: FetchControllerType;
  startAiFetch: (reqData: AiExecuteRequestType | AiTestRequest) => void;
  endAiFetch: () => void;
  resetAiFetch: () => void;
};

export type MultiFetchControllerRes = {
  fetchControllerMap: { [key: string]: FetchControllerType };
  startAiFetch: (modelCode: string, reqData: AiExecuteRequestType) => void;
  endAiFetch: (modeCode: string) => void;
  resetAiFetch: () => void;
};

export function useAigcMultiModelCodesFetchController(modelCodes: string[]): MultiFetchControllerRes {
  const forceUpdate = useForceUpdate();
  const [fetchControllerMap] = useState<{ [key: string]: FetchControllerType }>({
    '': {
      requesting: false,
      reader: null,
      controller: null,
      result: '',
      abort: false,
      done: false,
      error: false
    }
  });

  const resetAiFetch = () => {
    Object.keys(fetchControllerMap).forEach(modelCode => {
      const fetchController = fetchControllerMap[modelCode];
      fetchController.requesting = false;
      fetchController.controller = null;
      fetchController.reader = null;
      fetchController.result = '';
      fetchController.abort = false;
      fetchController.done = false;
      fetchController.error = false;
    });
    forceUpdate();
  };

  const startAiFetch = async (modelCode: string, reqData: AiExecuteRequestType) => {
    const fetchController = fetchControllerMap[modelCode];
    if (!fetchController) {
      return;
    }
    await executeAiFetch({
      fetchController,
      update: forceUpdate,
      reqData
    });
  };

  const endAiFetch = async (modelCode: string) => stopExecAiFetch(fetchControllerMap[modelCode], forceUpdate);

  useEffect(() => {
    if (!modelCodes) {
      return;
    }
    modelCodes.forEach(code => {
      fetchControllerMap[code] = {
        requesting: false,
        reader: null,
        controller: null,
        result: '',
        abort: false,
        done: false,
        error: false
      };
    });
  }, modelCodes);

  return {
    fetchControllerMap,
    startAiFetch,
    endAiFetch,
    resetAiFetch
  } as MultiFetchControllerRes;
}

export function useAigcExecuteFetchController(): FetchControllerRes {
  const forceUpdate = useForceUpdate();
  const [fetchController] = useState<FetchControllerType>({
    requesting: false,
    reader: null,
    controller: null,
    result: '',
    abort: false,
    done: false,
    error: false
  });

  const resetAiFetch = () => {
    fetchController.requesting = false;
    fetchController.controller = null;
    fetchController.reader = null;
    fetchController.result = '';
    fetchController.abort = false;
    fetchController.done = false;
    fetchController.error = false;
    forceUpdate();
  };

  const startAiFetch = async (reqData: AiExecuteRequestType) =>
    await executeAiFetch({
      fetchController,
      update: forceUpdate,
      reqData
    });

  const endAiFetch = async () => stopExecAiFetch(fetchController, forceUpdate);

  return {
    fetchController,
    startAiFetch,
    endAiFetch,
    resetAiFetch
  } as FetchControllerRes;
}

export function useAigcTestFetchController(): FetchControllerRes {
  const forceUpdate = useForceUpdate();
  const [fetchController] = useState<FetchControllerType>({
    requesting: false,
    reader: null,
    controller: null,
    result: '',
    abort: false,
    done: false,
    error: false
  });

  const resetAiFetch = () => {
    fetchController.requesting = false;
    fetchController.controller = null;
    fetchController.reader = null;
    fetchController.result = '';
    fetchController.abort = false;
    fetchController.done = false;
    fetchController.error = false;
    forceUpdate();
  };

  const startAiFetch = async (reqData: AiTestRequest) =>
    await executeAiFetch({
      fetchController,
      update: forceUpdate,
      reqData,
      url: '/api/ai/test'
    });

  const endAiFetch = async () => stopExecAiFetch(fetchController, forceUpdate);

  return {
    fetchController,
    startAiFetch,
    endAiFetch,
    resetAiFetch
  } as FetchControllerRes;
}

export type VertionFetchControllerType = {
  key: string;
  label: string;
  uid: string; // 当前版本的历史记录 uid
  parentUid?: string; // 父版本的历史记录 uid
  isRoot?: boolean; // 是否是根节点，第一个 tab 就是根节点
  configUid: string; // 当前咒语的 uid
  inputValues: CommonObjectType;
  inputValuesForSave?: CommonObjectType;
  requestUid: string;
  versionTips?: string;
} & FetchControllerType;

export type startNewVertionFuncType = (args: {
  tabKey?: string;
  tabTitle?: string;
  requestUid?: string;
  executeOptConfig?: ExecutionOptConfigItem;
  reqData: AiExecuteRequestType;
  parentUid?: string;
  isRoot?: boolean;
}) => void;

export function useAigcFetchVertionController() {
  const [vertionControllers, setVertionControllers] = useState<Array<VertionFetchControllerType>>([]);
  const [activeKey, setActiveKey] = useState('');
  const forceUpdate = useForceUpdate();

  const reset = () => {
    vertionControllers.length = 0;
  };

  const startNewVertion: startNewVertionFuncType = ({ tabKey, tabTitle, isRoot, requestUid, executeOptConfig, reqData, parentUid }) => {
    let versionTips = executeOptConfig?.versionTips;
    // ${版本} replaced by 父版本名称
    if (executeOptConfig?.versionTips?.includes('${版本}')) {
      const parentObj = vertionControllers.find(item => item.uid === parentUid) || {
        label: '原始版本'
      };
      versionTips = executeOptConfig.versionTips.replace('${版本}', `  ${parentObj.label}  `);
    }
    const newTabKey = tabKey || `${vertionControllers.length + 1}`;
    const fetchController: VertionFetchControllerType = {
      key: newTabKey,
      label: tabTitle || `${executeOptConfig?.name}v${vertionControllers.length + 1}`,
      uid: '', // 初始化为空，等保存历史记录后再赋值
      parentUid,
      configUid: reqData.uid || '',
      isRoot,
      inputValues: reqData.values || {},
      inputValuesForSave: reqData.valuesForSave,
      versionTips,
      requestUid: requestUid || uuidv4(),
      requesting: false,
      reader: null,
      controller: null,
      result: '',
      abort: false,
      done: false,
      error: false
    };
    setVertionControllers(prev => [...prev, fetchController]);
    setActiveKey(newTabKey);
    executeAiFetch({
      fetchController,
      update: forceUpdate,
      reqData
    });
  };

  const endAiFetch = async (tabIndex: number) => {
    if (tabIndex === -1) {
      vertionControllers.forEach(item => {
        if (item.requesting) {
          stopExecAiFetch(item, forceUpdate);
        }
      });
    } else {
      stopExecAiFetch(vertionControllers[tabIndex], forceUpdate);
    }
  };

  return {
    vertionControllers,
    reset,
    startNewVertion,
    activeKey,
    setActiveKey,
    endAiFetch
  };
}

export function useListenFetchController(
  fetchController: FetchControllerType,
  options: {
    abortCallback?: () => void;
    doneCallback?: () => void;
    errorCallback?: () => void;
  }
) {
  useEffect(() => {
    if (fetchController.requesting) {
      return;
    }
    if (fetchController.done) {
      options?.doneCallback?.();
    } else if (fetchController.abort) {
      options?.abortCallback?.();
    } else if (fetchController.error) {
      options?.errorCallback?.();
    }
  }, [fetchController.requesting, fetchController.abort, fetchController.done, fetchController.error]);
}

export function useForceUpdate() {
  const [_, update] = useState(0);

  return () => update(prev => prev + 1);
}

export function useLoadAiModels() {
  const [loading, setLoading] = useState(false);
  const [aiModels, setAiModels] = useState([]);

  const reload = async () => {
    setLoading(true);
    return fetch('/api/ai-model', { method: 'GET' })
      .then(res => res.json())
      .then(res => {
        if (!res.success) {
          return Promise.reject(res.error);
        }
        setAiModels(res.data || []);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    reload();
  }, []);

  return {
    loading,
    aiModels,
    reload,
    setAiModels
  };
}

/**
 * 请求函数 hook
 * @export
 * @param {(args: any) => Promise<T>} service
 */
export function useFetch<T>(service: (args: any) => Promise<T | null>, dependence: any[], args?: any) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<T | null>(null);

  const reload = async () => {
    setLoading(true);
    try {
      const ret = await service(args);
      setData(ret || null);
    } catch {
      setData(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    reload();
  }, dependence);

  return {
    loading,
    data,
    reload,
    setData
  } as {
    loading: boolean;
    data: T;
    reload: () => Promise<void>;
    setData: (v: T | null) => void;
  };
}

export function useHashLocation() {
  const [pathname, setPathname] = useState('');
  const [searchParams, setSearchParams] = useState<URLSearchParams | null>(null);

  useEffect(() => {
    const parseHashUrl = () => {
      if (location.hash.includes('?')) {
        const [pathStr, searchStr] = location.hash.split('?');
        setPathname(pathStr.replace('#', ''));
        setSearchParams(new URLSearchParams(searchStr));
      } else {
        setPathname(location.hash.replace('#', ''));
        setSearchParams(null);
      }
    };
    parseHashUrl();
    window.addEventListener('hashchange', parseHashUrl);

    return () => window.removeEventListener('hashchange', parseHashUrl);
  }, []);

  return {
    pathname,
    searchParams
  };
}

export function useClientSession() {
  const { data: session, status } = useSession();
  return {
    session,
    status
  } as {
    session: SessionType;
    status: 'loading' | 'authenticated' | 'unauthenticated';
  };
}

type UseModalParams = {
  submit: () => Promise<void>;
  callback?: () => void;
  closeCallback?: () => void;
};

export function useModal({ submit, callback, closeCallback }: UseModalParams) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const onCancel = () => {
    setOpen(false);
    closeCallback?.();
  };

  const onOk = async () => {
    setLoading(true);
    try {
      await submit();
      callback?.();
      setOpen(false);
    } catch (e) {
      //
    } finally {
      setLoading(false);
    }
  };

  return {
    open,
    onCancel,
    onOk,
    showModal,
    okText: '确定',
    cancelText: '取消',
    okButtonProps: {
      loading
    },
    cancelButtonProps: {
      loading
    }
  };
}

// 组件 resize 的处理 hooks，具有本地缓存功能
export function useResizeWithLocalStorage(localStorageKey: string, defaultWidth: number) {
  const [width, setWidth] = useState(Number(localStorage.getItem(localStorageKey) || defaultWidth));

  const updateWidth = (diff: number) => {
    const newWidth = new BigNumber(width).plus(diff).toNumber();
    setWidth(newWidth);
    localStorage.setItem(localStorageKey, String(newWidth));
  };

  return {
    width,
    updateWidth
  };
}

export const useSupportArrayAt = (modal: HookAPI) => {
  useEffect(() => {
    const supportAt = supportArrayAt();
    // console.log(`是否支持数组at : `, supportAt);
    if (supportAt) {
      return;
    }
    modal.confirm({
      title: '您的浏览器需要更新',
      content: (
        <div>
          <div style={{ marginTop: 10, marginBottom: 5 }}>
            你所使用的浏览器已过时，无法正确打开本网站功能，请下载并安装最新版Chrome浏览器进行使用，感谢您的配合~
          </div>
          <a href="https://www.google.cn/chrome/" target="_blank">
            下载地址
          </a>
        </div>
      ),
      okText: '我知道了',
      cancelButtonProps: { style: { display: 'none' } }
    });
  }, []);
};
