export function supportArrayAt(): boolean {
  const arr = [0, 1];
  return !!arr.at;
}

export function shuffleArray(array: Array<any>) {
  if (!array || array.length <= 1) {
    return array;
  }
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}
