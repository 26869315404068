import { Dispatch, FC, SetStateAction } from 'react';
import { Prisma } from '@prisma/client';
import { BizTypeEnum } from './libs/constants';
import { NextApiResponse } from 'next';
import { AiModelAuth } from '@/pages/api/ai-model/types';
import { AsyncEventEmitter } from './libs/events';

export type CommonObjectType<T = any> = Record<string, T>;

export type SetStateFn<T> = Dispatch<SetStateAction<T>>;

export type ErrorType = {
  code?: string;
  message: string;
};

// =========== 登陆的用户 ==========
export type SessionType = {
  user: SessionUser;
  expires: string;
};

export type SessionUser = {
  // id: string,
  /**
   * 用户id
   */
  uid: string;
  /**
   * 用户名
   */
  userName: string;
  /**
   * 用户昵称
   */
  nickName: string;
  /**
   * 当前登陆的租户uid
   */
  loginTenantUid: string;
};

export interface ComponentProps {
  path: string;
  searchParams: URLSearchParams | null;
  event: AsyncEventEmitter;
}

// 多页签类型
export interface PanesItemProps {
  icon: string;
  title: string;
  content: FC<ComponentProps> | null;
  key: string;
  closable: boolean;
  path: string;
  searchParams: URLSearchParams | null;
  isHiddenSideMenu: boolean;
}

// 菜单路由类型
export interface MenuItemType {
  key: string;
  name: string;
  path?: string;
  children?: MenuItemType[];
  icon?: string;
  isHiddenSideMenu?: boolean;
}

export interface UserInfo {
  id: string;
  userName: string;
  nickName?: string;
  phone?: string;
}

export enum SpellConfigParamTypeEnum {
  SingleText = 'SINGLE_TEXT',
  MultipleText = 'MULTIPLE_TEXT',
  Selection = 'SELECTION'
}

export const SpellConfigParamTypeDesc = {
  [SpellConfigParamTypeEnum.SingleText]: '单行文本',
  [SpellConfigParamTypeEnum.MultipleText]: '多行文本',
  [SpellConfigParamTypeEnum.Selection]: '下拉选择'
};

export enum SpellConfigParamModeEnum {
  Required = 'REQUIRED', // 必填
  Optional = 'OPTIONAL', // 选填
  Ignored = 'IGNORED' // 忽视，表示不参与咒语生成，是噪音项
}

export const SpellConfigParamModeEnumDesc = {
  [SpellConfigParamModeEnum.Required]: '必填',
  [SpellConfigParamModeEnum.Optional]: '选填',
  [SpellConfigParamModeEnum.Ignored]: '干扰'
};

export type SpellConfigParamTypeOptionForSelection = {
  label: string;
  value?: string;
  tips?: string;
};

export type SpellConfigParamType = {
  name: string; // 参数名
  placeholder: string; //参数placeholder
  type: SpellConfigParamTypeEnum; // 行参数类型
  typeOption?: string; // 参数的配置，json表示。针对不同type有不同配置；对SELECTION，类型是 ConfigParamTypeOptionForSelection
  mode: SpellConfigParamModeEnum; // 参数是选填还是必填，默认必填
};

export type SpellConfigType = {
  pattern: string; // 模板
  parameters: SpellConfigParamType[]; // 替换参数
  extraConfig: string; // 额外配置
};

export type SpellConfigFullType = {
  pattern: string; // 模板
  parameters: SpellConfigParamType[]; // 替换参数
  extraConfig: string; // 额外配置
  temperature: Prisma.Decimal;
};

export type SpellFullDetailType = {
  uid: string;
  snapshotUid: string;
  name: string;
  groupUid: string;
  sequence: number;
  status: string;
  help: string | null;
  aiModelCode: string;
} & SpellConfigFullType;

export type AiExecuteRequestType = {
  uid?: string; // 咒语 uid
  snapshotUid?: string; // 咒语快照 uid
  values: Record<string, string | null>; // 用户输入值
  valuesForSave?: Record<string, string | null>;
  requestUid?: string;
  modelCode?: string; // 指定运行的模型
  modelAuthUid?: string;
  ignoreModelStatus?: boolean;
  parentHistoryUid?: string; // 上一次执行历史记录
  rootHistoryUid?: string; // 初始执行历史记录
};

export type TreeSelectSpellItem = {
  title: string;
  value: string;
  bizType: BizTypeEnum;
  children: TreeSelectSpellItem[];
};

/** ===============================================
 * 请求和返回类型
 * ================================================ **/
export type BaseResponse<T> = {
  success: boolean;
  data?: T;
  error?: string | null;
};

export type PaginationResponse<T> = {
  success: boolean;
  data?: T;
  error?: string | null | undefined;
  total: number;
  page?: number;
};

export function success<T>(res: NextApiResponse<BaseResponse<T>>, data?: T) {
  return res.status(200).send({
    success: true,
    data: data
  });
}

export function successPage<T>(res: NextApiResponse<PaginationResponse<T>>, total: number, data?: T) {
  return res.status(200).send({
    success: true,
    data: data,
    total
  });
}

export function failedPage(res: NextApiResponse<PaginationResponse<any>>, msg: string | null | undefined) {
  return res.status(200).send({
    success: false,
    error: msg,
    total: 0
  });
}

export function failed(res: NextApiResponse<BaseResponse<any>>, msg: string | null | undefined) {
  return res.status(200).send({
    success: false,
    error: msg
  });
}

export type AliYunEmbeddingResponse = {
  output: {
    embeddings: { text_index: number; embedding: [number] }[];
  };
  usage: {
    total_tokens: number;
  };
};

export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;
