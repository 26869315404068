import React, { FC, ReactNode } from 'react';
import { Modal } from 'antd';
import styles from './style.module.scss';
import Image from 'next/image';
import dashLogo from '../../../public/dash-logo.png';
import loginPng from '../../../public/login/login.png';

const LoginModal: FC<{
  children: ReactNode;
  onCancel: () => void;
}> = ({ children, onCancel }) => {
  return (
    <Modal className={styles['modal']} style={{ padding: 0 }} centered open={true} width={964} footer={[]} onCancel={onCancel}>
      <div className={styles['modal-container']}>
        <div className={styles['modal-container-left']}>
          <div className={styles['modal-container-left-logo']}>
            <Image alt="logo" src={dashLogo} width={165} height={40} />
          </div>
          <Image alt="image" src={loginPng} width={420} height={600} />
        </div>
        <div className={styles['modal-container-right']}>{children}</div>
      </div>
    </Modal>
  );
};

export default LoginModal;
