import React, { FC, useEffect, useState } from 'react';
import { Button, Form, Input, message, Modal, Popover, Select, Space } from 'antd';

import { LockOutlined, MobileOutlined } from '@ant-design/icons';
import { signOut, useSession } from 'next-auth/react';
import { SendPhoneSecret } from '@/components/HomePage/Invitation';
import { EncryptData } from '@/libs/RSAUtil';
import styles from './style.module.scss';
import Image from 'next/image';
import dashLogo from '../../../../public/dash-logo.png';
import { SmsTypeEnum } from '@/libs/constants';
import { pwdRegex } from '@/libs/pwdUtils';
import type { HomeType } from '@/components/HomePage';
import TimerButton from '@/components/TimerButton';

import loginPng from '../../../../public/login/login.png';
import { NamePath } from 'rc-field-form/es/interface';
import LoginModal from '@/components/HomePage/LoginModal';

const UpdSecret: FC<{
  updHomeType: (v: HomeType) => void;
}> = ({ updHomeType }) => {
  const [form] = Form.useForm();

  const { data: session } = useSession();

  const time = 60;
  const [countdown, setCountdown] = useState(time);
  const [isDisabled, setIsDisabled] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  // CR: 使用 useFetch
  useEffect(() => {
    if (!session?.user?.uid) {
      return;
    }
    fetch('/api/user/getUserInfo', { method: 'GET' })
      .then(res => res.json())
      .then(({ success, error, data }) => {
        if (success) {
          form.setFieldValue('phone' as NamePath, data?.phone);
        }
      });
  }, []);

  async function updatePwd(values: any) {
    setBtnLoading(true);
    await fetch('/api/user/updSecret', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        phone: EncryptData(values.phone),
        password: EncryptData(values.password),
        phoneToken: values.phoneToken
      })
    })
      .then(res => res.json())
      .then(async res => {
        if (res?.success) {
          message.success('修改密码成功，已退出登陆');
          await signOut({ redirect: false });
          window.location.href = `/#/login`;
          window.location.reload();
        } else {
          message.error(res.error || '修改密码失败');
        }
      })
      .finally(() => {
        setBtnLoading(false);
      });
  }

  const sendPhoneSecret = async () => {
    await form.validateFields(['phone'] as NamePath[]).then(async value => {
      const { success, error } = await SendPhoneSecret(value.phone, false, SmsTypeEnum.MODIFY_SECRET);
      if (success) {
        message.success('验证码发送成功！');
        setIsDisabled(true);
      } else {
        message.error(error || '出错了');
      }
    });
  };

  useEffect(() => {
    let timer = null;
    if (countdown > 0 && isDisabled) {
      timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
    } else if (countdown === 0 && isDisabled) {
      setIsDisabled(false);
      setCountdown(time);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [countdown, isDisabled]);

  return (
    <LoginModal onCancel={() => updHomeType('home')}>
      <span className={styles['modal-container-right-login-title']}>更改密码</span>

      <Form style={{ marginTop: 48, width: 368 }} form={form} onFinish={updatePwd} size="large">
        <Form.Item name="phone" rules={[{ required: true, message: '手机号不能为空' }, { validator: validatePhone }]}>
          <Input prefix={<MobileOutlined />} placeholder="11位手机号" maxLength={11} disabled={!!session?.user?.uid} autoComplete="tel-local" />
        </Form.Item>
        <Form.Item name="phoneToken" rules={[{ required: true, message: '验证码不能为空' }]}>
          <Space.Compact style={{ width: '100%' }}>
            <Input type="text" placeholder="输入验证码" maxLength={6} autoComplete="one-time-code" />
            <TimerButton btnText="获取验证码" onClick={sendPhoneSecret} style={{ marginLeft: 8 }} />
          </Space.Compact>
        </Form.Item>

        <Form.Item name="password" rules={[{ required: true, message: '请输入8-18位密码，区分大小写' }, { validator: validatePwd }]}>
          <Input.Password placeholder="密码" prefix={<LockOutlined />} autoComplete="current-password" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" className={styles['modal-container-right-submit-btn']} loading={btnLoading}>
            修改
          </Button>
        </Form.Item>

        {session?.user?.uid ? null : (
          <div style={{ marginLeft: '133px', display: 'flex' }}>
            <span>想起密码</span>
            <a onClick={() => updHomeType('login')}>&nbsp;去登陆</a>
          </div>
        )}
      </Form>
    </LoginModal>
  );
};

export default UpdSecret;

type Validator = (rule: any, value: any, callback: (error?: string) => void) => Promise<void | any> | void;

export const validateUserName: Validator = (rule, value, callback) => {
  const reg = /^[a-zA-Z][a-zA-Z0-9_]{5,17}$/;
  if (value && !reg.test(value)) {
    callback('请输入6～18位的英文数字或者_');
  } else {
    callback();
  }
};

export const validatePhone: Validator = (rule, value, callback) => {
  const reg = /^1[3-9]\d{9}$/;
  if (value && !reg.test(value)) {
    callback('11位手机号');
  } else {
    callback();
  }
};

export const validatePwd: Validator = (rule, value, callback) => {
  if (value && !pwdRegex.test(value)) {
    callback('请输入至少包含字符和数字两种符号的8-18位密码');
  } else {
    callback();
  }
};
