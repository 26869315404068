import React, { FC, useEffect, useState } from 'react';
import { App, Button, Form, Input, message, Modal, Select, Space, Spin, Tabs } from 'antd';
import { EncryptData } from '@/libs/RSAUtil';
import styles from './style.module.scss';
import { LockOutlined, MobileOutlined, SmileOutlined, UserOutlined } from '@ant-design/icons';
import UserAvatar from '@/components/UserAvatar';
import { RegistryType, SmsTypeEnum } from '@/libs/constants';
import type { HomeType } from '@/components/HomePage';
import TimerButton from '@/components/TimerButton';
import { ValidateInviteTokeResponse } from '@/pages/api/user/validateInviteToken';
import { NamePath } from 'rc-field-form/es/interface';
import { AccountFieldType, PhoneFieldType } from '@/components/HomePage/Login';
import { useClientSession, useFetch, useSupportArrayAt } from '@/libs/fe/hooks';
import { BaseResponse } from '@/types';
import { changeTenant } from '@/components/ChangeTenant';
import { validatePwd } from '@/components/HomePage/UpdSecret';
import LoginModal from '@/components/HomePage/LoginModal';

import { validatePhone, validateUserName } from '@/components/HomePage/UpdSecret';
import Head from 'next/head';

const RegistryForm: FC<{
  registryForm: any;
  sendPhoneSecret: () => void;
  inviteValidateResult: BaseResponse<ValidateInviteTokeResponse>;
}> = ({ sendPhoneSecret, registryForm, inviteValidateResult }) => {
  return (
    <Form form={registryForm}>
      <Form.Item name="userName" rules={[{ required: true, message: '请输入用户名' }, { validator: validateUserName }]}>
        <Input placeholder="请输入用户名" prefix={<UserOutlined />} autoComplete="username" />
      </Form.Item>

      <Form.Item name="nickName" rules={[{ required: inviteValidateResult?.data?.isNickNameRequired, message: '请输入昵称' }]}>
        <Input placeholder="请输入昵称，展示你是谁，可用汉字" prefix={<SmileOutlined />} autoComplete="name" />
      </Form.Item>

      <Form.Item name="password" rules={[{ required: true, message: '密码不能为空' }, { validator: validatePwd }]}>
        <Input.Password placeholder="请输入8～18位密码，区分大小写" prefix={<LockOutlined />} autoComplete="current-password" />
      </Form.Item>

      <Form.Item name="phone" rules={[{ required: true, message: '请输入手机号' }, { validator: validatePhone }]}>
        <Input prefix={<MobileOutlined />} placeholder="11位手机号" maxLength={11} autoComplete="tel-local" />
      </Form.Item>

      <Form.Item name="phoneToken" rules={[{ required: true, message: '验证码不能为空' }]}>
        <Space style={{ width: '100%' }}>
          <Input placeholder="输入验证码" autoComplete="one-time-code" style={{ width: 260 }} />
          <TimerButton btnText="获取验证码" onClick={sendPhoneSecret} />
        </Space>
      </Form.Item>
    </Form>
  );
};

const LoginRegistryForm: FC<{
  accountForm: any;
  phoneForm: any;
  sendPhoneSecret: () => void;
  registryType: RegistryType;
  setRegistryType: (v: RegistryType) => void;
}> = ({ sendPhoneSecret, accountForm, phoneForm, registryType, setRegistryType }) => {
  return (
    <Tabs centered activeKey={registryType} onChange={activeKey => setRegistryType(activeKey as RegistryType)}>
      <Tabs.TabPane key={'ACCOUNT'} tab={'账号密码登录'}>
        <Form form={accountForm} size="large">
          <Form.Item<AccountFieldType> name="username" rules={[{ required: true, message: '账户不能为空' }]}>
            <Input placeholder="账户" prefix={<UserOutlined />} autoComplete="username" />
          </Form.Item>

          <Form.Item<AccountFieldType> name="password" rules={[{ required: true, message: '密码不能为空' }]}>
            <Input.Password placeholder="密码" prefix={<LockOutlined />} autoComplete="current-password" />
          </Form.Item>
        </Form>
      </Tabs.TabPane>
      <Tabs.TabPane key={'PHONE'} tab={'手机号登录'}>
        <Form form={phoneForm} size="large">
          <Form.Item<PhoneFieldType> name="phone" rules={[{ required: true, message: '手机号不能为空' }, { validator: validatePhone }]}>
            <Input placeholder="手机号" prefix={<MobileOutlined />} autoComplete="tel-local" />
          </Form.Item>

          <Form.Item<PhoneFieldType> name="phoneToken" rules={[{ required: true, message: '验证码不能为空' }]}>
            <Space>
              <Input.Password type="text" placeholder="输入验证码" prefix={<LockOutlined />} maxLength={6} autoComplete="one-time-code" />
              <TimerButton btnText="获取验证码" onClick={sendPhoneSecret} />
            </Space>
          </Form.Item>
        </Form>
      </Tabs.TabPane>
    </Tabs>
  );
};

const invitation: FC<{
  updHomeType: (v: HomeType) => void;
  token: string;
}> = ({ updHomeType, token }) => {
  const errMsg = '邀请链接错误或已失效';
  const { session } = useClientSession();

  const [registryRes, setRegistryRes] = useState({
    success: false,
    data: {
      userName: '',
      nickName: ''
    }
  });
  const [isRegistry, setIsRegistry] = useState(true);
  const [registryType, setRegistryType] = useState<RegistryType>('ACCOUNT');
  const [registryForm] = Form.useForm();
  const [accountForm] = Form.useForm();
  const [phoneForm] = Form.useForm();

  const [btnLoading, setBtnLoading] = useState(false);

  const {
    data: inviteValidateResult,
    reload,
    loading
  } = useFetch<BaseResponse<ValidateInviteTokeResponse>>(
    async token => {
      return await fetch('/api/user/validateInviteToken', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ inviteToken: token })
      })
        .then(res => res.json())
        .then(res => {
          // res.success = false
          return res;
        });
    },
    [token, session],
    token
  );

  async function registryWhenLoginIn() {
    if (inviteValidateResult?.data?.isGrantedTenant) {
      const callbackUrl = `${location.origin}/workspace#/dashboard/home`;
      //已授权
      if (session?.user?.loginTenantUid === inviteValidateResult?.data?.inviteTenantUid) {
        location.href = callbackUrl;
      } else {
        await changeTenant(inviteValidateResult.data?.inviteTenantUid, session, callbackUrl);
      }
    } else {
      //提交申请
      await register({
        registryType: 'CONFIRM'
      });
    }
  }

  async function registry() {
    try {
      setBtnLoading(true);
      if (session?.user?.uid) {
        await registryWhenLoginIn();
        return;
      }
      if (isRegistry) {
        registryForm.validateFields().then(async values => {
          await register({
            userName: values.userName,
            nickName: values.nickName || values.userName,
            password: values.password,
            phone: values.phone,
            phoneToken: values.phoneToken,
            registryType: 'REGISTRY'
          });
        });
      } else if (registryType === 'ACCOUNT') {
        accountForm.validateFields().then(async values => {
          await register({
            userName: values.userName,
            password: values.password,
            registryType: registryType
          });
        });
      } else if (registryType === 'PHONE') {
        phoneForm.validateFields().then(async values => {
          await register({
            phone: values.phone,
            phoneToken: values.phoneToken,
            registryType: registryType
          });
        });
      }
    } finally {
      setBtnLoading(false);
    }
  }

  async function register({
    userName,
    nickName,
    password,
    phone,
    phoneToken,
    registryType
  }: {
    userName?: string;
    nickName?: string;
    password?: string;
    phone?: string;
    phoneToken?: string;
    registryType: RegistryType;
  }) {
    await fetch('/api/user/registryUser', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        userName: EncryptData(userName),
        password: EncryptData(password),
        nickName: EncryptData(nickName),
        phone: EncryptData(phone),
        phoneToken: phoneToken,
        inviteToken: token,
        registryType: registryType
      })
    })
      .then(res => res.json())
      .then(res => {
        if (res?.success) {
          message.success('提交申请成功');
          setRegistryRes({
            success: true,
            data: {
              userName: res.data.userName,
              nickName: res.data.nickName
            }
          });
        } else {
          message.error(res.error || '注册失败');
        }
      })
      .catch(res => {
        message.error(res || '注册失败');
      });
  }

  const sendPhoneSecret = async () => {
    await (isRegistry ? registryForm : phoneForm).validateFields(['phone'] as NamePath[]).then(async value => {
      const { success, error } = await SendPhoneSecret(value.phone, isRegistry, SmsTypeEnum.REGISTRY);
      if (success) {
        message.success('验证码发送成功！');
      } else {
        message.error(error || '出错了');
      }
    });
  };

  const handleKeyPress = async (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      if (inviteValidateResult?.success) {
        await registry();
      }
    }
  };

  useEffect(() => {
    // 在组件挂载时添加事件监听器
    document.addEventListener('keydown', handleKeyPress);
    // 在组件卸载时移除事件监听器
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [isRegistry, registryType, registryRes?.success, session, inviteValidateResult]);

  return (
    <LoginModal onCancel={() => updHomeType('home')}>
      <div style={{ marginTop: 32 }}>
        <Spin spinning={loading}>
          {loading ? (
            <h2 style={{ marginTop: 250, marginLeft: 100 }}></h2> // 加载中
          ) : !inviteValidateResult?.success ? (
            <h2 style={{ marginTop: 250, marginLeft: 100 }}>邀请链接错误或已失效</h2> // 邀请失效
          ) : registryRes?.success ? (
            // 已注册
            <div style={{ marginTop: 150 }}>
              <h1>{`${registryRes.data.nickName ? registryRes.data.nickName + '，' : ''}您好～`}</h1>
              <h2
                style={{ marginTop: 50 }}
              >{`您的${inviteValidateResult?.data?.inviteTenantName}团队加入申请已提交，请耐心等待管理员的确认，感谢您的配合～`}</h2>
            </div>
          ) : (
            // 未注册
            <div style={{ display: 'flex', flexDirection: 'column', width: '368px' }}>
              <div className={styles['modal-container-right-invitation-head']}>
                <UserAvatar size={72} />
                <div style={{ width: '100%', marginLeft: '16px' }}>
                  <div className={styles['modal-container-right-invitation-head-inviter']}>
                    {inviteValidateResult?.data?.inviterUserNickName || ''}邀请您加入
                  </div>
                  <div className={styles['modal-container-right-invitation-head-tenant']}>{inviteValidateResult?.data?.inviteTenantName || ''}</div>
                </div>
              </div>

              {session?.user?.uid ? (
                //登陆状态下
                inviteValidateResult?.data?.isGrantedTenant ? (
                  //有租户权限时
                  <div style={{ marginTop: 64, marginBottom: 24, fontSize: 18 }}>
                    <p style={{ marginBottom: 18 }}>您在该团队下已有账号</p>
                    <p>点击下方按钮可直接访问工作台</p>
                  </div>
                ) : (
                  //无租户权限时
                  <div style={{ marginTop: 64, marginBottom: 24, fontSize: 18 }}>
                    <p style={{ marginBottom: 18 }}>{`您是否希望使用现已登录的账号【${session?.user?.nickName}】访问该团队？`}</p>
                    <p>点击下方按钮确认，提交申请</p>
                  </div>
                )
              ) : //非登陆状态下
              isRegistry ? (
                <RegistryForm registryForm={registryForm} sendPhoneSecret={sendPhoneSecret} inviteValidateResult={inviteValidateResult} />
              ) : (
                <LoginRegistryForm
                  accountForm={accountForm}
                  phoneForm={phoneForm}
                  registryType={registryType}
                  setRegistryType={setRegistryType}
                  sendPhoneSecret={sendPhoneSecret}
                />
              )}

              <Button
                type="primary"
                htmlType="submit"
                loading={btnLoading}
                className={styles['modal-container-right-submit-btn']}
                onClick={async () => {
                  await registry();
                }}
              >
                {session?.user?.uid //登陆状态下
                  ? inviteValidateResult?.data?.isGrantedTenant
                    ? '登陆'
                    : '提交申请'
                  : //未登陆状态时
                  isRegistry
                  ? '注册'
                  : '登录'}
              </Button>
              <div style={{ marginTop: 16, marginLeft: 58 }}>
                <span>{!session?.user?.uid && isRegistry ? '注册' : '登录'}即表示您已阅读并同意</span>
                <a>《用户协议》</a>
              </div>
              {session?.user?.uid ? null : (
                <div style={{ marginTop: '36px' }}>
                  <a style={{ float: 'left' }} onClick={() => updHomeType('login')}>
                    已有账号登陆
                  </a>
                  <a style={{ float: 'right' }} onClick={() => setIsRegistry(!isRegistry)}>
                    {isRegistry ? '绑定现有账号信息' : '注册新账号'}
                  </a>
                </div>
              )}
            </div>
          )}
        </Spin>
      </div>
    </LoginModal>
  );
};

export async function SendPhoneSecret(phone: string, isRegister: boolean, smsType: SmsTypeEnum) {
  if (!phone) {
    return {
      success: false,
      error: '请输入手机号'
    };
  }
  return await fetch('/api/user/sendPhoneSecret', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      phone: EncryptData(phone),
      type: isRegister ? 'CRT' : 'MDF',
      smsType: smsType
    })
  })
    .then(res => res.json())
    .then((res: any) => {
      return {
        success: res.success,
        error: res.error
      };
    });
}

export default invitation;
