import React, { useState, useEffect, FC } from 'react';
import { Button } from 'antd';
import { useAliyunCaptcha } from '@/libs/fe/hooks/useAliyunCaptcha';
import { v4 as uuidv4 } from 'uuid';

type Props = {
  duration?: number;
  btnText: string;
  onCountdownFinish?: () => void;
  className?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
  // validator?: () => Promise<boolean>;
};

const TimerButton: FC<Props> = ({
  duration,
  className,
  onClick,
  onCountdownFinish,
  btnText,
  style
  // validator
}) => {
  const time = duration || 60;
  const [countdown, setCountdown] = useState(time);
  const [isDisabled, setIsDisabled] = useState(false);
  const [btnId] = useState(`btn-${uuidv4()}`);

  const [loading, setLoading] = useState(false);

  async function getWithParams(url: string, params: {}) {
    return await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(params)
    })
      .then(res => res.json())
      .then(async res => {
        return res.data;
      });
  }

  const captchaVerifyCallback = async (captchaVerifyParam: any) => {
    try {
      // 后端请求逻辑
      const result = await getWithParams('/api/captcha/verify', {
        // yourBizParam... // 业务参数
        captchaVerifyParam // 验证码参数
      });

      return {
        captchaResult: result.captchaVerifyResult,
        // captchaResult: odd,
        //短信验证无需进行业务校验
        bizResult: true
      };
    } catch (e) {
      return {
        captchaResult: true,
        // captchaResult: odd,
        //短信验证无需进行业务校验
        bizResult: true
      };
    }
  };

  const onBizResultCallback = async (bizResult: any) => {
    await handleButtonClick();
  };

  useAliyunCaptcha(
    {
      element: `#element-${btnId}`,
      button: `#${btnId}`,
      captchaVerifyCallback: captchaVerifyCallback,
      onBizResultCallback: onBizResultCallback
    },
    [btnId]
  );

  useEffect(() => {
    let timer = undefined;
    if (countdown > 0 && isDisabled) {
      timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
    } else if (countdown === 0 && isDisabled) {
      setIsDisabled(false);
      setCountdown(time);
      onCountdownFinish?.();
    }
    return () => clearTimeout(timer);
  }, [countdown, isDisabled]);

  const handleButtonClick = async () => {
    try {
      setLoading(true);
      await onClick?.();
    } finally {
      setLoading(false);
    }
    setIsDisabled(true);
  };

  return (
    <>
      <Button
        loading={loading}
        id={btnId}
        className={className}
        disabled={isDisabled}
        style={style}
        // onClick={handleButtonClick}
      >
        {isDisabled ? countdown + 's 后重试' : btnText}
      </Button>
      <div id={`element-${btnId}`}></div>
    </>
  );
};

export default TimerButton;
