import { useEffect } from 'react';

export function useAliyunCaptcha(
  {
    element,
    button,
    captchaVerifyCallback,
    onBizResultCallback
  }: {
    element: string;
    button: string;
    captchaVerifyCallback: Function;
    onBizResultCallback: Function;
  },
  dependence: any[]
) {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://o.alicdn.com/captcha-frontend/aliyunCaptcha/AliyunCaptcha.js';
    document.body.appendChild(script);

    // console.log('start onload ', new Date().getTime());
    script.onload = () => {
      let captcha;

      initAliyunCaptcha({
        SceneId: '1nci1n3n', // 场景ID。根据步骤二新建验证场景后，您可以在验证码场景列表，获取该场景的场景ID
        prefix: '7eclok', // 身份标。开通阿里云验证码2.0后，您可以在控制台概览页面的实例基本信息卡片区域，获取身份标
        mode: 'popup', // 验证码模式。popup表示要集成的验证码模式为弹出式。无需修改
        element: element,
        button: button,
        captchaVerifyCallback: captchaVerifyCallback,
        onBizResultCallback: onBizResultCallback,
        getInstance: getInstance,
        slideStyle: {
          width: 360,
          height: 40
        },
        language: 'cn'
      });

      function getInstance(instance) {
        captcha = instance;
      }
    };

    return () => {
      document.body.removeChild(script);
    };
  }, dependence);

  return null;
}
